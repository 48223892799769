.map-image {
    background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 30%
      );
      background-size: 100% 100%;
      background-size: cover;
  }

 .map-image:nth-child(1) {
  background-image: url("./2.jpeg");
  background-position: center;
  }

  .map-image:nth-child(2) {
    background-image: url("./1.jpeg");
    background-position: center;
  }

  .map-image:nth-child(3) {
    background-image: url("./3.jpeg");
  } 

  