.container {
    max-width: 90%;
    margin: 20px auto;
    padding-bottom: 50px;
    /* padding: 20px; */
    box-sizing: border-box;
    /* background: #1e1e1e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
    border-radius: 10px;
}
  .container h1{
    margin-top: 80px;
    margin-bottom: 100px;
  }
  h1, h2 {
    text-align: center;
    color: #bb86fc;
  }
  p {
    margin-bottom: 1em;
  }
  .image-1, .image-2 {
    width: 100%;
    margin: 0 auto;
    max-width: 850px;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .center{
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }
  ul li {
    background: #333;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  ul li::before {
    content: "✔";
    margin-right: 10px;
    color: #bb86fc;
  }
  .link-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px;
    text-align: center;
    background-color: #bb86fc;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  }